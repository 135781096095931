var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_vm.items ? _c('b-col', [_c('b-form', [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: '/brands'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-primary p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Display Premiums Offers Brands (" + _vm._s(this.name) + ")")])]), this.totalRows == 0 ? _c('div', [_c('b-jumbotron', {
    staticClass: "bg-transparent",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Oops, Data Not Found")];
      },
      proxy: true
    }, {
      key: "lead",
      fn: function () {
        return [_vm._v(" We Didn't Found Any Premiums Item in This Brand Please Create New Premium for this Brand ")];
      },
      proxy: true
    }], null, false, 1581602476)
  }, [_c('b-button', {
    staticClass: "btn btn-sm btn-dark col-sm-auto",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": _vm.createAction
    }
  }, [_vm._v("Create New Premiums Offers")])], 1)], 1) : this.totalRows > 0 ? _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto mt-5 mb-0"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    staticStyle: {
      "width": "30px",
      "height": "75%"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.createAction
    }
  }, [_c('em', {
    staticClass: "fa fa-plus"
  })]), _c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatNumber(this.totalRows)))])])]) : _vm._e(), _c('br'), _vm.totalRows > 0 ? _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "table-responsesive"
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "responsive": "xl",
      "hover": "",
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(delete-action)",
      fn: function (row) {
        return [_c('button', {
          staticClass: "btn btn-sm btn-danger",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteAction(row.item.premiumsId, row.item.premiumName);
            }
          }
        }, [_c('em', {
          staticClass: "fa fa-trash"
        })])];
      }
    }], null, false, 211633075)
  })], 1)]) : _vm._e()])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }