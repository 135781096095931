<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
   
      <b-col v-if='items'>
        <b-form >
          <router-link class="btn btn-sm btn-dark"  size="sm"  :to="{ path: '/brands'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Display Premiums Offers Brands ({{this.name}})</h5>
            </div>
            <div v-if='this.totalRows==0'>
              <b-jumbotron class='bg-transparent'>
                <template #header>Oops, Data Not Found</template>
                <template #lead>
                  We Didn't Found Any Premiums Item in This Brand Please Create New Premium for this Brand
                </template>
                <b-button variant="primary"  class="btn btn-sm btn-dark col-sm-auto"
                type="button"  @click="createAction">Create New Premiums Offers</b-button>
              </b-jumbotron>
            </div>
            <div class="row justify-content-end" v-else-if='this.totalRows>0'>    
              <div class="col-sm-auto mt-5 mb-0">
                <button
                  class="btn btn-sm btn-dark "
                  type="button"
                  @click="createAction"
                  style="width: 30px; height: 75%"
                >
                  <em class="fa fa-plus"></em>
                </button>
                <label class="col-sm-auto">Count </label>
                <strong class="col-sm-auto">{{ formatNumber(this.totalRows) }}</strong>
                </div>
              </div>
            <br/>
            <div class="card-body" v-if='totalRows>0'>
              <div class="table-responsesive">
                <b-table 
                  sticky-header="500px"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  :isLoading="isLoading"
                  show-empty
                  responsive="xl"
                  hover
                  sort-icon-left
                >
                <template #cell(delete-action)="row">
                  <button
                    class="btn btn-sm btn-danger "
                    type="button"
                    @click="deleteAction(row.item.premiumsId,row.item.premiumName)"
                  >
                    <em class="fa fa-trash"></em>
                  </button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-form>
    </b-col>
  </b-row>
</b-overlay>
</template>

<script>


import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import constant from "../../store/constant";
export default {
  name: 'DisplayPremiumsPath',
  data() {
    return {
      isLoadingSearch: false,
      items_search: [],
      debounce: null,
      id: null,
      currentPage: 1,
      perPage: 20,
      fields: [
        { key:'premiumName', label: 'Premiums Name'},
        { key:'offerStatus', label: 'Offer Status', formatter:function(value){
          if (value==constant.OFFER_STATUS.NEW){
            return 'NEW';
          }
          else if (value==constant.OFFER_STATUS.WAITING_FOR_REVIEW){
            return 'WAITING_FOR_REVIEW';
          }
          else if (value==constant.OFFER_STATUS.IN_REVIEW){
            return 'IN_REVIEW';
          }
          else if (value==constant.OFFER_STATUS.REJECTED){
            return 'REJECTED';
          }
          else if (value==constant.OFFER_STATUS.APPROVED){
            return 'APPROVED';
          }
          else if (value==constant.OFFER_STATUS.PREPARE_FOR_SALE){
            return 'PREPARE_FOR_SALE';
          }
          else if (value==constant.OFFER_STATUS.NOT_FOR_SALE){
            return 'READY_FOR_SALE';
          }
          else if (value==constant.OFFER_STATUS.NOT_FOR_SALE){
            return 'NOT_FOR_SALE';
          }else if(value==constant.OFFER_STATUS.READY_FOR_SALE){
            return 'Ready For Sale';
          } else {
            return value;
          }
        }},
        { key:'offerCode', label: 'Offer Code'},
        { key :'createdOffer', label:'Created (Offer)',formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
        },},
        { key :'modifiedOffer', label:'Modified (Offer)',formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
        },},
        { key:'delete-action', label: 'Delete'},
      ]
    };
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.brands.totalRows,
      items :(state) => state.brands.items,
      isLoading : (state) => state.brands.isLoading,
      isError : (state) => state.brands.isError,
      message: (state) => state.brands.successMessage,
      isCreate: (state) => state.brands.isCreate,
      name: (state) => state.brands.name,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Brands - Premiums | Content Management System Ebooks Gramedia.com';
      }
    },
    message : function () {
      if (!this.message) return;
      if (this.isError){
        this.messageAlert('error', this.message);
      }else{
        console.log('this message',this.message);
        this.messageAlert('success', this.message);
      }
    },
  },
  created() {
   this.actionGetBrandsPremiumsPremiums();
  },
  mounted() {
    this.actionGetBrandsPremiumsPremiums();
  },
  methods: {
...mapActions("brands", ["getPremiumsByBrandIdAdapter","getStatusItems","deletePremiums"]),
    actionGetBrandsPremiumsPremiums() {
      this.id = this.$route.params.id;
      this.getPremiumsByBrandIdAdapter(this.id);
    },messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },createAction(e) {
      e.preventDefault();
      if(this.isCreate==false){
          this.messageAlert('error', "Can Not Insert into Premiums Package, There is No Item With Status 'Ready For Consume'");
        }else{
        this.$router.push({
          path: `/brands/createPremiums/${this.id}`,
        });
      }
    },async deleteAction(premiumId,premiumName) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `Delete Premiums Offers ${premiumName}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let payload={
              offer_id:premiumId,
              id:this.id,
            }
            const response =  await this.deletePremiums(payload);
            const {result, message } = response;
            if(result) {
              this.$swal.fire(
              'Deleted!',
              message,
              'success'
              )
            }else {
              this.$swal.fire(
              'Failed!',
              message,
              'error'
              )
            }
            this.actionGetBrandsPremiumsPremiums();
          }
        });
    },formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return '0';
    },reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  }
};

</script>
<style scoped>
</style>
